export const requestNotificationPermission = async () => {
  if ('Notification' in window) {
    try {
      const permission = await Notification.requestPermission();
      console.log('Notification permission:', permission);
      return permission === 'granted';
    } catch (error) {
      console.error('Error requesting notification permission:', error);
      return false;
    }
  }
  console.log('Notifications not supported in this browser');
  return false;
};

export const scheduleLocalNotification = (notificationTime, callback) => {
  const now = new Date();
  const timeUntilNotification = notificationTime.getTime() - now.getTime();

  console.log('Scheduling notification for:', notificationTime);
  console.log('Time until notification:', timeUntilNotification, 'ms');

  if (timeUntilNotification > 0) {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      // Schedule notification using service worker
      navigator.serviceWorker.ready.then((registration) => {
        registration.active.postMessage({
          type: 'SCHEDULE_NOTIFICATION',
          payload: { time: notificationTime.toISOString() }
        });
        console.log('Notification scheduled via service worker');
      }).catch(error => {
        console.error('Error scheduling notification via service worker:', error);
        fallbackNotificationScheduling(timeUntilNotification, callback);
      });
    } else {
      console.log('Service Worker or Push API not supported, using fallback');
      fallbackNotificationScheduling(timeUntilNotification, callback);
    }
  } else {
    console.log('Notification time is in the past, triggering immediately');
    showNotification('Time to snap!');
    if (callback) callback();
  }
};

const fallbackNotificationScheduling = (timeUntilNotification, callback) => {
  // Fallback for browsers that don't support service workers
  setTimeout(() => {
    showNotification('Time to snap!');
    if (callback) callback();
  }, timeUntilNotification);
  console.log('Fallback notification scheduled');
};

export const cancelLocalNotification = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.active.postMessage({
        type: 'CANCEL_NOTIFICATION'
      });
      console.log('Notification cancellation request sent to service worker');
    }).catch(error => {
      console.error('Error cancelling notification via service worker:', error);
    });
  }
  console.log('Cancelling scheduled notification');
};

export const showNotification = (message) => {
  if ('Notification' in window && Notification.permission === 'granted') {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.showNotification('Snap Sketch Share', {
          body: message,
          icon: '/logo192.png',
          vibrate: [200, 100, 200],
          tag: 'snap-notification',
          renotify: true
        });
        console.log('Notification shown via service worker:', message);
      }).catch(error => {
        console.error('Error showing notification via service worker:', error);
        showFallbackNotification(message);
      });
    } else {
      showFallbackNotification(message);
    }
  } else {
    console.log('Notification not shown. Permission not granted or not supported.');
    alert(message); // Fallback to alert if notifications are not supported or permitted
  }
};

const showFallbackNotification = (message) => {
  // Fallback for browsers without service worker support
  new Notification('Snap Sketch Share', {
    body: message,
    icon: '/logo192.png'
  });
  console.log('Fallback notification shown:', message);
};

export const openCamera = async () => {
  try {
    const constraints = {
      video: { facingMode: 'environment' }
    };

    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      return handleCameraStream(stream);
    } else if ('getUserMedia' in navigator) {
      // Fallback for older browsers
      return new Promise((resolve, reject) => {
        navigator.getUserMedia(constraints, stream => resolve(handleCameraStream(stream)), error => reject(error));
      });
    } else {
      throw new Error('Camera access not supported in this browser');
    }
  } catch (error) {
    console.error('Error accessing camera:', error);
    throw new Error('Failed to access camera. Please check your permissions and try again.');
  }
};

const handleCameraStream = (stream) => {
  return new Promise((resolve) => {
    const videoElement = document.createElement('video');
    videoElement.srcObject = stream;
    videoElement.autoplay = true;
    videoElement.style.position = 'fixed';
    videoElement.style.top = '0';
    videoElement.style.left = '0';
    videoElement.style.width = '100%';
    videoElement.style.height = '100%';
    videoElement.style.objectFit = 'cover';
    videoElement.style.zIndex = '1000';

    const captureButton = document.createElement('button');
    captureButton.textContent = 'Capture';
    captureButton.style.position = 'fixed';
    captureButton.style.bottom = '20px';
    captureButton.style.left = '50%';
    captureButton.style.transform = 'translateX(-50%)';
    captureButton.style.zIndex = '1001';
    captureButton.style.padding = '10px 20px';
    captureButton.style.backgroundColor = '#4CAF50';
    captureButton.style.color = 'white';
    captureButton.style.border = 'none';
    captureButton.style.borderRadius = '5px';
    captureButton.style.cursor = 'pointer';

    document.body.appendChild(videoElement);
    document.body.appendChild(captureButton);

    captureButton.onclick = () => {
      const canvas = document.createElement('canvas');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      canvas.getContext('2d').drawImage(videoElement, 0, 0);
      stream.getTracks().forEach(track => track.stop());
      document.body.removeChild(videoElement);
      document.body.removeChild(captureButton);
      resolve(canvas.toDataURL('image/jpeg'));
    };
  });
};