import axios from 'axios';

const api = axios.create({
  baseURL: 'https://unkeen.com',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a response interceptor
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Logout the user if the token is no longer valid
      localStorage.removeItem('user_id');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const login = async (username, password) => {
  try {
    const response = await api.post('/login', { username, password });
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const register = async (username, password) => {
  try {
    const response = await api.post('/register', { username, password });
    return response.data;
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};

export const logout = async () => {
  try {
    const response = await api.post('/logout');
    return response.data;
  } catch (error) {
    console.error('Logout error:', error);
    throw error;
  }
};

export const storeImage = async (imageId) => {
  try {
    const response = await api.post('/store-image', { image_id: imageId });
    return response.data;
  } catch (error) {
    console.error('Store image error:', error);
    throw error;
  }
};

export const getUserSettings = async (userId) => {
  try {
    const response = await api.get(`/user-settings/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Get user settings error:', error);
    throw error;
  }
};

export const updateUserSettings = async (userId, settings) => {
  try {
    const response = await api.put(`/user-settings/${userId}`, settings);
    return response.data;
  } catch (error) {
    console.error('Update user settings error:', error);
    throw error;
  }
};

export const updateNextNotification = async (userId, nextNotificationTime) => {
  try {
    const response = await api.post(`/update-next-notification/${userId}`, { nextNotificationTime });
    return response.data;
  } catch (error) {
    console.error('Update next notification error:', error);
    throw error;
  }
};

export default api;