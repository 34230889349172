import React from 'react';
import { storeImage } from '../utils/api';
import { openCamera, showNotification } from '../utils/notifications';
import InstallPWA from './InstallPWA';

const MainApp = ({ startTime, endTime, scheduleNextNotification, showPhotoButton, setShowPhotoButton }) => {
  const handleTakePhoto = async () => {
    try {
      const imageData = await openCamera();
      console.log('Captured image data:', imageData);
      
      // Store image ID in the backend
      const imageId = Date.now().toString();
      await storeImage(imageId);

      setShowPhotoButton(false);
      
      // Schedule the next notification for tomorrow
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tomorrowStart = new Date(tomorrow.toDateString() + ' ' + startTime);
      const tomorrowEnd = new Date(tomorrow.toDateString() + ' ' + endTime);
      
      if (tomorrowEnd < tomorrowStart) {
        tomorrowEnd.setDate(tomorrowEnd.getDate() + 1);
      }
      
      const nextNotificationTime = new Date(tomorrowStart.getTime() + Math.random() * (tomorrowEnd.getTime() - tomorrowStart.getTime()));
      
      scheduleNextNotification(nextNotificationTime);
      
      showNotification('Image captured and stored successfully! Next notification scheduled for tomorrow.');
    } catch (error) {
      console.error('Error taking photo:', error);
      showNotification('Failed to take photo. Please try again.');
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Welcome to Snap Sketch Share</h1>
      {showPhotoButton && (
        <button
          onClick={handleTakePhoto}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-4 w-full"
        >
          Take Photo
        </button>
      )}
      {!showPhotoButton && (
        <p className="text-gray-600 mb-4">Wait for the notification to take your next photo!</p>
      )}
      <div className="mt-4">
        <InstallPWA />
      </div>
    </div>
  );
};

export default MainApp;