import React, { useState, useEffect } from 'react';

const BrowserSupport = ({ children }) => {
  const [isSupported, setIsSupported] = useState(true);
  const [issues, setIssues] = useState([]);

  useEffect(() => {
    const checkSupport = () => {
      const newIssues = [];

      if (!('serviceWorker' in navigator)) {
        newIssues.push('Service Workers are not supported.');
      }

      if (!('Notification' in window)) {
        newIssues.push('Notifications are not supported.');
      }

      if (!('mediaDevices' in navigator) || !('getUserMedia' in navigator.mediaDevices)) {
        newIssues.push('Camera access is not supported.');
      }

      setIssues(newIssues);
      setIsSupported(newIssues.length === 0);
    };

    checkSupport();
  }, []);

  if (isSupported) {
    return children;
  }

  return (
    <div className="p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
      <h2 className="font-bold">Browser Compatibility Issues</h2>
      <p>Your browser doesn't support some features required for the best experience:</p>
      <ul className="list-disc list-inside">
        {issues.map((issue, index) => (
          <li key={index}>{issue}</li>
        ))}
      </ul>
      <p className="mt-2">
        For the best experience, please use a modern browser like Chrome, Firefox, or Safari,
        or consider installing our app as a PWA.
      </p>
    </div>
  );
};

export default BrowserSupport;