import React from 'react';
import { Link } from 'react-router-dom';

const Welcome = () => {
  return (
    <div className="text-center">
      <h1 className="text-3xl font-bold mb-4">Welcome to Snap Sketch Share</h1>
      <p className="mb-6">Capture daily moments with random photo reminders.</p>
      <div className="space-x-4">
        <Link 
          to="/login" 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Login
        </Link>
        <Link 
          to="/register" 
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Register
        </Link>
      </div>
    </div>
  );
};

export default Welcome;