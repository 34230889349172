import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { requestNotificationPermission, scheduleLocalNotification, cancelLocalNotification } from './utils/notifications';
import AuthForm from './components/AuthForm';
import MainApp from './components/MainApp';
import Settings from './components/Settings';
import Header from './components/Header';
import Welcome from './components/Welcome';
import BrowserSupport from './components/BrowserSupport';
import { login, logout, getUserSettings, updateUserSettings, updateNextNotification } from './utils/api';

function App() {
  const [user, setUser] = useState(null);
  const [startTime, setStartTime] = useState('08:00');
  const [endTime, setEndTime] = useState('18:00');
  const [showPhotoButton, setShowPhotoButton] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    if (userId) {
      setUser(userId);
      fetchUserSettings(userId);
    }
    checkNotificationPermission();

    // Register service worker
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch(error => {
          console.error('Service Worker registration failed:', error);
        });
    }
  }, []);

  useEffect(() => {
    if (user && notificationPermission) {
      scheduleNextNotification();
    }
  }, [user, notificationPermission, startTime, endTime]);

  const fetchUserSettings = async (userId) => {
    try {
      const settings = await getUserSettings(userId);
      setStartTime(settings.startTime || '08:00');
      setEndTime(settings.endTime || '18:00');
    } catch (error) {
      console.error('Error fetching user settings:', error);
      if (error.response && error.response.status === 401) {
        setUser(null);
        localStorage.removeItem('user_id');
      }
    }
  };

  const checkNotificationPermission = async () => {
    const permission = await requestNotificationPermission();
    setNotificationPermission(permission);
    console.log('Notification permission set to:', permission);
  };

  const scheduleNextNotification = (specificTime = null) => {
    cancelLocalNotification();
    let notificationTime;

    if (specificTime) {
      notificationTime = new Date(specificTime);
    } else {
      const now = new Date();
      const start = new Date(now.toDateString() + ' ' + startTime);
      const end = new Date(now.toDateString() + ' ' + endTime);
      if (end < start) end.setDate(end.getDate() + 1);
      
      notificationTime = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
      if (notificationTime < now) notificationTime.setDate(notificationTime.getDate() + 1);
    }
    
    console.log('Scheduling next notification for:', notificationTime);
    scheduleLocalNotification(notificationTime, () => {
      console.log('Notification triggered, showing photo button');
      setShowPhotoButton(true);
    });

    // Send the next notification time to the server
    if (user) {
      updateNextNotification(user, notificationTime.toISOString())
        .then(() => console.log('Next notification time updated on server'))
        .catch(error => console.error('Failed to update next notification time on server:', error));
    }
  };

  const handleLogin = async (username, password) => {
    try {
      const data = await login(username, password);
      setUser(data.user_id);
      localStorage.setItem('user_id', data.user_id);
      await fetchUserSettings(data.user_id);
      checkNotificationPermission();
    } catch (error) {
      console.error('Login error:', error);
      // Handle login error (show message to user, etc.)
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      // Even if the server-side logout fails, clear the client-side state
      setUser(null);
      setStartTime('08:00');
      setEndTime('18:00');
      localStorage.clear();
      cancelLocalNotification();
      setShowPhotoButton(false);
    }
  };

  const updateTimeWindow = async (newStartTime, newEndTime) => {
    try {
      await updateUserSettings(user, { startTime: newStartTime, endTime: newEndTime });
      setStartTime(newStartTime);
      setEndTime(newEndTime);
      scheduleNextNotification();
    } catch (error) {
      console.error('Error updating time window:', error);
      // Handle error (show message to user, etc.)
    }
  };

  return (
    <Router>
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <Header isLoggedIn={!!user} handleLogout={handleLogout} />
        <div className="flex-grow flex items-center justify-center py-12">
          <div className="relative py-3 sm:max-w-xl sm:mx-auto">
            <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
            <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
              <div className="max-w-md mx-auto">
                <BrowserSupport>
                  <Routes>
                    <Route path="/" element={user ? (
                      <MainApp 
                        startTime={startTime}
                        endTime={endTime}
                        scheduleNextNotification={scheduleNextNotification}
                        showPhotoButton={showPhotoButton}
                        setShowPhotoButton={setShowPhotoButton}
                      />
                    ) : (
                      <Welcome />
                    )} />
                    <Route path="/login" element={<AuthForm onLogin={handleLogin} isLogin={true} />} />
                    <Route path="/register" element={<AuthForm onLogin={handleLogin} isLogin={false} />} />
                    <Route path="/settings" element={user ? (
                      <Settings 
                        startTime={startTime} 
                        endTime={endTime} 
                        updateTimeWindow={updateTimeWindow} 
                      />
                    ) : (
                      <Navigate to="/" replace />
                    )} />
                  </Routes>
                </BrowserSupport>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;